import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SyntaxHighlighter from 'react-syntax-highlighter'
import {
    atomOneDark,
    atomOneLight,
} from 'react-syntax-highlighter/dist/styles/hljs'
import { mimeTypeFromFileExtension, getContrastColor } from '../../utils/utils'
import Wysiwyg from '../wysiwyg/Wysiwyg'
import classNames from 'classnames'
import { ThemedComponent } from '../theme'
import './FlexContent.scss'

/**
 * Shared Fragments for FlexContent fields
 */
export const query = graphql`
    fragment FlexContentImageFragment on WordPressAcf_image {
        __typename
        ... on WordPressAcf_image {
            background_color
            image {
                alt_text
                title
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 1440 quality:95) {
                            presentationWidth
                            aspectRatio
                            src
                            srcSet
                            sizes
                        }
                    }
                }
            }
        }
    }
    fragment FlexContentTwoImageFragment on WordPressAcf_two_image {
        __typename
        ... on WordPressAcf_two_image {
            background_color
            image_left {
                alt_text
                title
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 1440 quality:90) {
                            presentationWidth
                            aspectRatio
                            src
                            srcSet
                            sizes
                        }
                    }
                }
            }
            image_r {
                alt_text
                title
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 1440 quality:90) {
                            presentationWidth
                            aspectRatio
                            src
                            srcSet
                            sizes
                        }
                    }
                }
            }
        }
    }
    fragment FlexContentQuoteFragment on WordPressAcf_quote {
        __typename
        ... on WordPressAcf_quote {
            background_color
            quote
            author
        }
    }
    fragment FlexContentTextHeadingFragment on WordPressAcf_text_heading {
        __typename
        ... on WordPressAcf_text_heading {
            background_color
            title
            content
        }
    }
    fragment FlexContentTextImageFragment on WordPressAcf_text_and_image {
        __typename
        ... on WordPressAcf_text_and_image {
            background_color
            content
            image_location
            image1 {
                alt_text
                title
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 2000) {
                            presentationWidth
                            aspectRatio
                            src
                            srcSet
                            sizes
                        }
                    }
                }
            }
        }
    }
    fragment FlexContentTwoTextFragment on WordPressAcf_two_text {
        __typename
        ... on WordPressAcf_two_text {
            background_color
            left_title
            left_content
            right_title
            right_content
        }
    }
    fragment FlexContentVideoFragment on WordPressAcf_single_video {
        __typename
        ... on WordPressAcf_single_video {
            background_color
            placeholder_image {
                localFile {
                    publicURL
                }
            }
            fucking_video {
                file {
                    localFile {
                        ext
                        publicURL
                    }
                }
            }
        }
    }
    fragment FlexContentTwoVideoFragment on WordPressAcf_two_video {
        __typename
        ... on WordPressAcf_two_video {
            background_color
            placeholder_left {
                localFile {
                    publicURL
                }
            }
            video_left {
                file {
                    localFile {
                        ext
                        publicURL
                    }
                }
            }
            placeholder_right {
                localFile {
                    publicURL
                }
            }
            video_right {
                file {
                    localFile {
                        ext
                        publicURL
                    }
                }
            }
        }
    }
    fragment FlexContentCodeFragment on WordPressAcf_code {
        __typename
        ... on WordPressAcf_code {
            background_color
            content
            language
        }
    }
`

export default ({ contents }) => {
    if (!contents) {
        return null
    }
    let sectionId = 0
    let lastColorBlock = null
    return contents.map((flexItem, i) => {
        let isStartOfColorBlock = false
        let isEndOfColorBlock = false
        if (flexItem.background_color !== lastColorBlock) {
            sectionId++
            lastColorBlock = flexItem.background_color
            isStartOfColorBlock = true
        }
        // console.log(
        //     `[${i}][${flexItem.background_color}] next -> [${
        //         contents[i + 1] ? contents[i + 1].background_color : ''
        //     }]`
        // )
        if (
            !contents[i + 1] ||
            contents[i + 1].background_color !== flexItem.background_color
        ) {
            isEndOfColorBlock = true
        }
        let FlexComponent = 'div'
        let props = {
            key: i,
            flexItem,
            sectionId,
            isStartOfColorBlock,
            isEndOfColorBlock,
        }
        let sectionName = ''
        switch (flexItem.__typename) {
            case 'WordPressAcf_image':
                if (
                    !flexItem.image ||
                    !flexItem.image.localFile ||
                    !flexItem.image.localFile.childImageSharp
                ) {
                    console.warn(
                        `FlexContent item [WordPressAcf_image] does not have an image field:`,
                        flexItem
                    )
                    return null
                }
                FlexComponent = FlexImage
                sectionName = 'image'
                break
            case 'WordPressAcf_two_image':
                if (!flexItem.image_left && !flexItem.image_r) {
                    console.warn(
                        `FlexContent item [WordPressAcf_two_image] does not have two image field:`,
                        flexItem
                    )
                    return null
                }
                FlexComponent = FlexTwoImage
                sectionName = 'two-image'
                break
            case 'WordPressAcf_quote':
                if (!flexItem.quote && !flexItem.author) {
                    console.warn(
                        `FlexContent item [WordPressAcf_quote] requires an author and or quote field:`,
                        flexItem
                    )
                    return null
                }
                FlexComponent = FlexQuote
                sectionName = 'quote'
                break
            case 'WordPressAcf_text_heading':
                if (!flexItem.title && !flexItem.content) {
                    console.warn(
                        `FlexContent item [WordPressAcf_text_heading] requires a title and or content field:`,
                        flexItem
                    )
                    return null
                }
                FlexComponent = FlexTextHeading
                sectionName = 'text-heading'
                break
            case 'WordPressAcf_text_and_image':
                if (
                    !flexItem.image_location ||
                    (!flexItem.content && !flexItem.image1)
                ) {
                    console.warn(
                        `FlexContent item [WordPressAcf_text_and_image] requires [image_location] and one or both of [image, content] fields:`,
                        flexItem
                    )
                    return null
                }
                FlexComponent = FlexTextImage
                sectionName = 'text-image'
                break
            case 'WordPressAcf_two_text':
                if (
                    !flexItem.left_title &&
                    !flexItem.left_content &&
                    !flexItem.right_title &&
                    !flexItem.right_content
                ) {
                    console.warn(
                        `FlexContent item [WordPressAcf_two_text] requires at least one of the following: left_title, left_content, right_title, right_content;`,
                        flexItem
                    )
                    return null
                }
                FlexComponent = FlexTwoText
                sectionName = 'two-text'
                break
            case 'WordPressAcf_single_video':
                if (
                    !flexItem.fucking_video ||
                    !Array.isArray(flexItem.fucking_video)
                ) {
                    console.warn(
                        `FlexContent item [WordPressAcf_video] requires video field to be an array:`,
                        flexItem
                    )
                    return null
                }
                FlexComponent = FlexVideo
                sectionName = 'video'
                break
            case 'WordPressAcf_two_video':
                if (
                    (!flexItem.video_left ||
                        !Array.isArray(flexItem.video_left)) &&
                    (!flexItem.video_right ||
                        !Array.isArray(flexItem.video_right))
                ) {
                    console.warn(
                        `FlexContent item [WordPressAcf_two_video] requires video field to be an array:`,
                        flexItem
                    )
                    return null
                }
                FlexComponent = FlexTwoVideo
                sectionName = 'two-video'
                break
            case 'WordPressAcf_code':
                if (!flexItem.content || !flexItem.language) {
                    console.warn(
                        `FlexContent item [WordPressAcf_code] requires both a content and language field:`,
                        flexItem
                    )
                    return null
                }
                FlexComponent = FlexCode
                sectionName = 'code'
                break
            default:
        }
        return (
            <Section
                key={i}
                sectionId={sectionId}
                background={flexItem.background_color}
                className={sectionName}
                start={isStartOfColorBlock}
                end={isEndOfColorBlock}
                dataBackground={flexItem.background_color}
            >
                <FlexComponent {...props} />
            </Section>
        )
    })
}

const Section = ({
    sectionId,
    sectionName,
    start,
    end,
    background,
    className,
    children,
}) => (
    <section
        className={classNames(
            `flex-item px-yd`,
            className,
            `section-${sectionId}`,
            {
                'section-group-start': start,
                'section-group-end': end,
            }
        )}
        style={{
            backgroundColor: background,
            color: background ? getContrastColor(background) : undefined,
        }}
    >
        {children}
    </section>
)

export const FlexImage = ({ flexItem: { image } }) => {
    return image ? (
        <Img
            fluid={image.localFile.childImageSharp.fluid}
            alt={image.alt_text}
            title={image.title}
        />
    ) : null
}

export const FlexTwoImage = ({ flexItem: { image_left, image_r } }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-6">
                {image_left && image_left.localFile && (
                    <Img
                        fluid={image_left.localFile.childImageSharp.fluid}
                        alt={image_left.alt_text}
                        title={image_left.title}
                    />
                )}
            </div>
            <div className="col-6">
                {image_r && image_r.localFile && (
                    <Img
                        fluid={image_r.localFile.childImageSharp.fluid}
                        alt={image_r.alt_text}
                        title={image_r.title}
                    />
                )}
            </div>
        </div>
    </div>
)

export const FlexQuote = ({ flexItem: { quote, author } }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-12">
                <div
                    className="type-subtitle"
                    dangerouslySetInnerHTML={{
                        __html: quote,
                    }}
                />
                {author && (
                    <div
                        className="type-h3"
                        dangerouslySetInnerHTML={{
                            __html: author,
                        }}
                    />
                )}
            </div>
        </div>
    </div>
)

export const FlexTextHeading = ({ flexItem: { title, content } }) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-12 col-sm-8 offset-sm-2">
                {title && (
                    <h2
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />
                )}
                <Wysiwyg>{content}</Wysiwyg>
            </div>
        </div>
    </div>
)

export const FlexTextImage = ({
    flexItem: { content, image_location, image1 },
}) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-12 col-sm-6">
                {image_location === 'left' ? (
                    <React.Fragment>
                        {image1 && image1.localFile.childImageSharp && (
                            <Img
                                fluid={image1.localFile.childImageSharp.fluid}
                                alt={image1.alt_text}
                                title={image1.title}
                            />
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {content && <Wysiwyg>{content}</Wysiwyg>}
                    </React.Fragment>
                )}
            </div>
            <div className="col-12 col-sm-6">
                {image_location === 'right' ? (
                    <React.Fragment>
                        {image1 && image1.localFile.childImageSharp && (
                            <Img
                                fluid={image1.localFile.childImageSharp.fluid}
                                alt={image1.alt_text}
                                title={image1.title}
                            />
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {content && <Wysiwyg>{content}</Wysiwyg>}
                    </React.Fragment>
                )}
            </div>
        </div>
    </div>
)

export const FlexTwoText = ({
    flexItem: { left_title, left_content, right_title, right_content },
}) => (
    <div className="container-fluid">
        <div className="row">
            <div
                className={`col-12 col-sm-6 ${
                    !right_title && !right_content ? '' : ''
                }`}
            >
                {left_title && (
                    <h3
                        dangerouslySetInnerHTML={{
                            __html: left_title,
                        }}
                    />
                )}
                <Wysiwyg>{left_content}</Wysiwyg>
            </div>
            <div
                className={`col-12 col-sm-6 ${
                    (!left_title && !left_content) ||
                    (!right_content && !right_title)
                        ? 'mt-0'
                        : ''
                }`}
            >
                {right_title && (
                    <h3
                        dangerouslySetInnerHTML={{
                            __html: right_title,
                        }}
                    />
                )}
                <Wysiwyg>{right_content}</Wysiwyg>
            </div>
        </div>
    </div>
)

export const FlexVideo = ({
    flexItem: { fucking_video, placeholder_image },
}) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-12">
                <video
                    style={{ width: '100%', height: 'auto' }}
                    autoPlay
                    muted
                    loop
                    playsInline
                    poster={
                        placeholder_image && placeholder_image.localFile
                            ? placeholder_image.localFile.publicURL
                            : null
                    }
                >
                    {fucking_video.map(
                        (
                            {
                                file: {
                                    localFile: { publicURL, ext },
                                },
                            },
                            index
                        ) => (
                            <source
                                key={index}
                                src={publicURL}
                                type={mimeTypeFromFileExtension(ext)}
                            />
                        )
                    )}
                </video>
            </div>
        </div>
    </div>
)

export const FlexTwoVideo = ({
    flexItem: { video_left, placeholder_left, video_right, placeholder_right },
}) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-6">
                <video
                    style={{ width: '100%', height: 'auto' }}
                    autoPlay
                    muted
                    loop
                    playsInline
                    poster={
                        placeholder_left && placeholder_left.localFile
                            ? placeholder_left.localFile.publicURL
                            : null
                    }
                >
                    {video_left &&
                        Array.isArray(video_left) &&
                        video_left.map(
                            (
                                {
                                    file: {
                                        localFile: { publicURL, ext },
                                    },
                                },
                                index
                            ) => (
                                <source
                                    key={index}
                                    src={publicURL}
                                    type={mimeTypeFromFileExtension(ext)}
                                />
                            )
                        )}
                </video>
            </div>
            <div className="col-6">
                <video
                    style={{ width: '100%', height: 'auto' }}
                    autoPlay
                    muted
                    loop
                    playsInline
                    poster={
                        placeholder_right && placeholder_right.localFile
                            ? placeholder_right.localFile.publicURL
                            : null
                    }
                >
                    {video_right &&
                        Array.isArray(video_right) &&
                        video_right.map(
                            (
                                {
                                    file: {
                                        localFile: { publicURL, ext },
                                    },
                                },
                                index
                            ) => (
                                <source
                                    key={index}
                                    src={publicURL}
                                    type={mimeTypeFromFileExtension(ext)}
                                />
                            )
                        )}
                </video>
            </div>
        </div>
    </div>
)

export class FlexCode extends ThemedComponent {
    render() {
        // console.log(SyntaxHighlighter.supportedLanguages)
        const {
            flexItem: { content, language },
        } = this.props
        const { name: themeName } = this.context.theme
        const syntaxStyle = themeName === 'dark' ? atomOneDark : atomOneLight
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-8 offset-md-2">
                        <SyntaxHighlighter
                            language={language}
                            style={syntaxStyle}
                        >
                            {content}
                        </SyntaxHighlighter>
                    </div>
                </div>
            </div>
        )
    }
}
