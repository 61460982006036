import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Headroom from 'react-headroom'
import Modal from 'react-modal'
import './style.scss'
import { NavIconOpen, NavIconClose } from './HeaderIcons'
import LogoIcon from '../icons/Logo'

Modal.setAppElement('#___gatsby')

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            navMenuOpen: false,
        }
    }
    componentDidMount() {
        window.addEventListener('resize', this._forceRender)
        this._forceRender()
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this._forceRender)
    }
    _forceRender = () => {
        this.forceUpdate()
    }
    _toggleMainMenu = () => {
        this.setState({ navMenuOpen: !this.state.navMenuOpen })
    }
    render() {
        const {
            siteTitle,
            menuItems,
            fixed,
            headroomProps,
            themeOveride,
        } = this.props
        const isMobile =
            typeof window !== 'undefined' ? window.innerWidth < 768 : false
        let isFixed = isMobile && this.state.navMenuOpen ? true : fixed
        const navbarIconColor = themeOveride
            ? themeOveride === 'dark'
                ? '#FFFFFF'
                : '#000000'
            : undefined
        return (
            <React.Fragment>
                <Headroom
                    {...headroomProps}
                    disable={
                        (this.state.navMenuOpen && !isMobile) ||
                        headroomProps.disable
                    }
                    style={{
                        WebkitTransition: 'all .25s ease-out 0',
                        MozTransition: 'all .25s ease-out 0',
                        OTransition: 'all .25s ease-out 0',
                        transition: 'all .25s ease-out 0',
                    }}
                    wrapperStyle={{
                        position: isFixed ? 'fixed' : 'absolute',
                        width: '100%',
                        zIndex: 9999,
                        top: isFixed ? 0 : undefined,
                    }}
                >
                    <header
                        className={`Header navigation ${
                            themeOveride ? themeOveride : ''
                        }`}
                    >
                        <nav
                            className={['navbar', 'navbar-expand-md'].join(' ')}
                        >
                            <Link
                                className="navbar-brand"
                                to="/"
                                title={siteTitle}
                            >
                                <LogoIcon color={navbarIconColor} />
                            </Link>
                            {isMobile ? (
                                <button
                                    className="btn btn-toggle-nav"
                                    onClick={this._toggleMainMenu}
                                >
                                    {this.state.navMenuOpen ? (
                                        <NavIconClose />
                                    ) : (
                                        <NavIconOpen color={navbarIconColor} />
                                    )}
                                </button>
                            ) : (
                                <ul
                                    className={
                                        'navbar-nav ml-auto d-flex flex-row'
                                    }
                                >
                                    {menuItems.map((item, i) => {
                                        const link_to = `/${item.object_slug}`
                                        return (
                                            <li
                                                key={i}
                                                className={'nav-item type-link'}
                                            >
                                                <Link
                                                    to={link_to}
                                                    className="nav-link"
                                                >
                                                    {item.title}
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            )}
                        </nav>
                    </header>
                </Headroom>
                <Modal
                    isOpen={this.state.navMenuOpen}
                    contentLabel="Mobile navigation menu"
                    overlayClassName="modal-main-menu-overlay"
                    className="modal-main-menu"
                    bodyOpenClassName="modal-navigation-open"
                    onRequestClose={this._toggleMainMenu}
                >
                    <ul className={'navbar-nav'}>
                        {menuItems.map((item, i) => {
                            const link_to = `/${item.object_slug}`
                            return (
                                <li key={i} className={'nav-item'}>
                                    <Link
                                        to={link_to}
                                        className="nav-link type-link"
                                        onClick={this._toggleMainMenu.bind(
                                            this
                                        )} //This exists in the case that you click on a page you're already on.
                                    >
                                        {item.title}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </Modal>
            </React.Fragment>
        )
    }
}

export const MenuItemPropType = PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    object_slug: PropTypes.string.isRequired,
})

Header.propTypes = {
    siteTitle: PropTypes.string.isRequired,
    menuItems: PropTypes.arrayOf(MenuItemPropType).isRequired,
    fixed: PropTypes.bool.isRequired,
    headroomProps: PropTypes.object,
    themeOveride: PropTypes.oneOf(['light', 'dark']),
}

Header.defaultProps = {
    siteTitle: '',
    menuItems: [],
    fixed: true,
    headroomProps: {},
    themeOveride: null,
}

export default Header
