import React, { Component } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Facebook from './Facebook'
import Twitter from './Twitter'
import { author as copyrightHolder } from '../../../config/website'
import logoSrc from '../../assets/logo.png'

export const SEOPropTypes = {
    // page specific props
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.string,
    article: PropTypes.bool,
    datePublished: PropTypes.string,
    dateModified: PropTypes.string,
    author: PropTypes.string,
    // site specific props
    siteLanguage: PropTypes.string,
    ogLanguage: PropTypes.string,
    siteUrl: PropTypes.string,
    facebook: PropTypes.string,
    twitter: PropTypes.string,
}

export default class SEO extends Component {
    static propTypes = SEOPropTypes
    render() {
        let {
            title,
            description,
            type,
            url,
            image,
            article,
            dateModified,
            datePublished,
            author,
            siteLanguage,
            ogLanguage,
            siteUrl,
            facebook,
            twitter,
            yoast,
        } = this.props
        description = description.replace(/(<([^>]+)>)/gi, '').substring(0, 160)
        const copyrightYear = new Date().getFullYear()

        // schema.org in JSONLD format
        // https://developers.google.com/search/docs/guides/intro-structured-data
        // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')
        const schemaOrgWebPage = {
            '@context': 'http://schema.org',
            '@type': 'WebPage',
            url: url,
            headline: title,
            inLanguage: siteLanguage,
            mainEntityOfPage: url,
            description: description,
            author: {
                '@type': type,
                name: author,
            },
            copyrightHolder: {
                '@type': 'Organization',
                name: copyrightHolder,
            },
            copyrightYear: copyrightYear,
            creator: {
                '@type': type,
                name: author || copyrightHolder,
            },
            publisher: {
                '@type': 'Organization',
                name: copyrightHolder,
            },
            datePublished: datePublished,
            dateModified: dateModified,
            image: image
                ? {
                      '@type': 'ImageObject',
                      url: image,
                  }
                : undefined,
        }
        // Initial breadcrumb list
        const itemListElement = [
            {
                '@type': 'ListItem',
                item: {
                    '@id': siteUrl,
                    name: 'Home',
                },
                position: 1,
            },
        ]
        let schemaArticle = null
        if (article) {
            schemaArticle = {
                '@context': 'http://schema.org',
                '@type': 'Article',
                author: {
                    '@type': 'Person',
                    name: author,
                },
                copyrightHolder: {
                    '@type': 'Organization',
                    name: copyrightHolder,
                },
                copyrightYear: copyrightYear,
                creator: {
                    '@type': 'Person',
                    name: author || copyrightHolder,
                },
                publisher: {
                    '@type': 'Organization',
                    name: copyrightHolder,
                    logo: {
                        '@type': 'ImageObject',
                        url: logoSrc,
                    },
                },
                datePublished: datePublished,
                dateModified: dateModified,
                description: description,
                headline: title,
                inLanguage: siteLanguage,
                url: url,
                name: title,
                image: image
                    ? {
                          '@type': 'ImageObject',
                          url: image,
                      }
                    : undefined,
                mainEntityOfPage: url,
            }
            // Push current blogpost into breadcrumb list
            itemListElement.push({
                '@type': 'ListItem',
                item: {
                    '@id': url,
                    name: title,
                },
                position: 2,
            })
        }
        const breadcrumb = {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            description: 'Breadcrumbs list',
            name: 'Breadcrumbs',
            itemListElement,
        }
        return (
            <>
                <Helmet title={title}>
                    <html lang={siteLanguage} />
                    <meta name="description" content={description} />
                    <meta name="image" content={image} />
                    <link rel="canonical" href={yoast.canonical || url} />
                    {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
                    {!article && (
                        <script type="application/ld+json">
                            {JSON.stringify(schemaOrgWebPage)}
                        </script>
                    )}
                    {article && (
                        <script type="application/ld+json">
                            {JSON.stringify(schemaArticle)}
                        </script>
                    )}
                    <script type="application/ld+json">
                        {JSON.stringify(breadcrumb)}
                    </script>
                </Helmet>
                <Facebook
                    description={yoast.opengraph_description || description}
                    image={yoast.opengraph_image || image}
                    title={yoast.opengraph_title || title}
                    type={article ? 'article' : 'website'}
                    url={url}
                    locale={ogLanguage}
                    name={facebook}
                />
                <Twitter
                    title={yoast.twitter_title || title}
                    image={yoast.twitter_image || image}
                    description={yoast.twitter_description || description}
                    username={twitter}
                />
            </>
        )
    }
}
