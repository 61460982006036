import React from 'react'
import { ThemedComponent } from '../theme'

/**
 * Passing a color value will override the page theme
 */
export default class extends ThemedComponent {
    render() {
        const { textColor } = this.context.theme
        const { color } = this.props
        let fill = color
        if (!fill) {
            fill = textColor
        }
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 91">
                <title>Y-Designs</title>
                <path
                    fill={fill}
                    d="M24.36,59.25l-14-38.32H0L19.47,71.49l-2,5.29C16,80.51,13.74,82.1,9.74,82.1H5.11v8.72h6c7.55,0,12-3.21,14.84-10.73l23-59.17H38.47Z"
                />
                <path
                    fill={fill}
                    d="M140.64.17V27a20.51,20.51,0,0,0-15.94-7.21c-13.87,0-23.94,10.87-23.94,25.84s10.07,25.66,24.48,25.66a20.17,20.17,0,0,0,15.67-7.11v5.94H150V.17ZM125.7,62.61c-9.35,0-15.4-6.68-15.4-17,0-10.08,6.26-17.11,15.22-17.11,9.21,0,15.4,6.88,15.4,17.11C140.91,55.61,134.66,62.61,125.7,62.61Z"
                />
                <path
                    fill={fill}
                    d="M79.08,53.62a17.83,17.83,0,0,1-8-2.11,14.38,14.38,0,0,0-6.17-1.76,7.34,7.34,0,0,0-4.55,1.6A5.42,5.42,0,0,1,57,52.61a4.81,4.81,0,0,1-4.77-4.84c0-5.16,6.13-7.94,11.89-7.94A17,17,0,0,1,72.08,42a13.79,13.79,0,0,0,6.15,1.83,7.59,7.59,0,0,0,4.59-1.56,5.52,5.52,0,0,1,3.31-1.22A4.81,4.81,0,0,1,91,45.83C91,50.95,85,53.62,79.08,53.62Z"
                />
            </svg>
        )
    }
}
