import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import Hoverable from '../hoverable/Hoverable'
import './IdeasListItem.scss'

const IdeasListItem = ({ featured, post }) => {
    const link =
        post.__typename === 'wordpress__POST'
            ? `/ideas/stories/${post.slug}`
            : `/ideas/tutorials/${post.slug}`
    return featured ? (
        <IdeasListItemFeatured {...post} link={link} />
    ) : (
        <IdeasListItemDefault {...post} link={link} />
    )
}

const IdeasListItemDefault = ({
    link,
    __typename,
    slug,
    title,
    dateFormatted,
    defaultMedia,
}) => (
    <Hoverable.Container>
        <Link
            className={`IdeasListItem default`}
            to={link}
            style={{ height: '100%' }}
        >
            <Hoverable.Overlay
                solidOverlay={true}
                style={{ width: '100%', height: '100%' }}
            >
                {defaultMedia &&
                defaultMedia.localFile &&
                defaultMedia.localFile.childImageSharp ? (
                    <div className="image-overlay">
                        <Img
                            fluid={defaultMedia.localFile.childImageSharp.fluid}
                            alt={defaultMedia.alt_text}
                            title={defaultMedia.title}
                        />
                    </div>
                ) : (
                    <div className="image-overlay-placeholder" />
                )}
            </Hoverable.Overlay>
            <div className="content-overlay">
                <div className="d-flex flex-column h-100">
                    <div className="type-link mb-auto">{dateFormatted}</div>
                    <div
                        className="title"
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <div className="type-link">
                        {__typename === 'wordpress__POST'
                            ? 'Stories'
                            : 'Tutorials'}
                    </div>
                </div>
            </div>
        </Link>
    </Hoverable.Container>
)

const IdeasListItemFeatured = () => <div className={`IdeasListItem featured`} />

IdeasListItem.propTypes = {
    featured: PropTypes.bool.isRequired,
}

IdeasListItem.defaultProps = {
    featured: false,
}

export default IdeasListItem

export const query = graphql`
    fragment IdeasStoryListItemFragment on wordpress__POST {
        __typename
        id
        slug
        title
        content
        dateCompare: date(formatString: "x")
        dateFormatted: date(formatString: "MM.DD.YYYY")
        defaultMedia: featured_media {
            alt_text
            title
            localFile {
                childImageSharp {
                    fluid(maxWidth: 828, maxHeight: 950) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
        featuredMedia: featured_media {
            alt_text
            title
            localFile {
                childImageSharp {
                    fluid(maxWidth: 600, maxHeight: 750) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
    }
    fragment IdeasTutorialListItemFragment on wordpress__wp_tutorials {
        __typename
        id
        slug
        title
        content
        dateCompare: date(formatString: "x")
        dateFormatted: date(formatString: "MM.DD.YYYY")
        defaultMedia: featured_media {
            alt_text
            title
            localFile {
                childImageSharp {
                    fluid(maxWidth: 828, maxHeight: 950) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
        featuredMedia: featured_media {
            alt_text
            title
            localFile {
                childImageSharp {
                    fluid(maxWidth: 600, maxHeight: 750) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
    }
`
