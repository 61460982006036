module.exports = {
    pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
    title: 'Y-Designs - Seattle Digital Agency', // Navigation and Site Title
    titleAlt: 'Y-Designs - Seattle Digital Agency', // Title for JSONLD
    type: 'Organization',
    description:
        'We create websites, brands, and products that help businesses and organizations excel. Our work ranges from web design, application development, branding, print design, and SEO.',
    headline:
        'We create websites, brands, and products that help businesses and organizations excel. Our work ranges from web design, application development, branding, print design, and SEO.', // Headline for schema.org JSONLD
    url: 'https://y-designs.com', // Domain of your site. No trailing slash!
    cmsUrl: 'https://wp.y-designs.com', // Domain of data source for this site (currently used for href re-writing)
    siteLanguage: 'en', // Language Tag on <html> element
    ogLanguage: 'en_US', // Facebook Language

    // JSONLD / Manifest
    favicon: 'src/favicon.png', // Used for manifest favicon generation
    shortName: 'Y-Designs', // shortname for manifest. MUST be shorter than 12 characters
    author: 'Y-Designs, Inc', // Author for schemaORGJSONLD
    themeColor: '#3D63AE',
    backgroundColor: '#EBEDF2',

    twitter: '@ydseattle', // Twitter Username
    facebook: 'Y-Designs, Inc', // Facebook Site Name
}
