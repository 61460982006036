import React from 'react'
import PropTypes from 'prop-types'
import PageTheme, { ThemePropType } from '../components/theme'

/**
 * LayoutBase:
 * Used as the entry point for site-wide stylesheet and
 * fonts.
 */
const LayoutBase = ({
    children,
    className,
    theme,
    headerSpacing = true,
    ...props
}) => (
    <PageTheme theme={theme}>
        <div
            className={`LayoutBase ${className} ${
                headerSpacing ? 'header-spacing' : ''
            }`}
            {...props}
        >
            {children}
        </div>
    </PageTheme>
)

LayoutBase.propTypes = {
    children: PropTypes.node.isRequired,
    theme: ThemePropType.isRequired,
}

export default LayoutBase
