import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import SEO from './SEO'
import { Location } from '@reach/router'

export const SEOWrappedPropTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.string,
    article: PropTypes.bool,
    datePublished: PropTypes.string,
    dateModified: PropTypes.string,
    author: PropTypes.string,
    yoast: PropTypes.shape({
        title: PropTypes.string,
        metadesc: PropTypes.string,
        meta_robots_noindex: PropTypes.string,
        canonical: PropTypes.string,
        opengraph_title: PropTypes.string,
        opengraph_description: PropTypes.string,
        opengraph_image: PropTypes.string,
        twitter_title: PropTypes.string,
        twitter_description: PropTypes.string,
        twitter_image: PropTypes.string,
    }),
}

export default class SEOWrapped extends Component {
    static propTypes = SEOWrappedPropTypes
    render() {
        const {
            title,
            description,
            type,
            image,
            pathname,
            article,
            dateModified,
            datePublished,
            author,
            yoast,
        } = this.props

        return (
            <Location>
                {({ location }) => (
                    <StaticQuery
                        query={query}
                        render={({
                            site: {
                                buildTime,
                                siteMetadata: {
                                    siteUrl,
                                    defaultTitle,
                                    defaultDescription,
                                    defaultType,
                                    defaultAuthor,
                                    siteLanguage,
                                    ogLanguage,
                                    twitter,
                                    facebook,
                                },
                            },
                        }) => {
                            return (
                                <SEO
                                    title={yoast.title || title || defaultTitle}
                                    description={
                                        yoast.metadesc ||
                                        description ||
                                        defaultDescription
                                    }
                                    type={type || defaultType}
                                    headline={title || defaultTitle}
                                    url={`${siteUrl}${pathname ||
                                        location.pathname}`}
                                    image={image}
                                    article={article}
                                    dateModified={dateModified || buildTime}
                                    datePublished={datePublished || buildTime}
                                    author={author || defaultAuthor}
                                    siteLanguage={siteLanguage}
                                    ogLanguage={ogLanguage}
                                    siteUrl={siteUrl}
                                    facebook={facebook}
                                    twitter={twitter}
                                    yoast={yoast}
                                />
                            )
                        }}
                    />
                )}
            </Location>
        )
    }
}

const query = graphql`
    fragment YoastWorks on wordpress__wp_works {
        date(formatString: "MMMM DD, YYYY")
        modified(formatString: "MMMM DD, YYYY")
        yoast {
            title
            metadesc
            meta_robots_noindex
            canonical
            opengraph_title
            opengraph_description
            opengraph_image
            twitter_title
            twitter_description
            twitter_image
        }
    }

    fragment YoastTutorials on wordpress__wp_tutorials {
        date(formatString: "MMMM DD, YYYY")
        modified(formatString: "MMMM DD, YYYY")
        yoast {
            title
            metadesc
            meta_robots_noindex
            canonical
            opengraph_title
            opengraph_description
            opengraph_image
            twitter_title
            twitter_description
            twitter_image
        }
    }

    fragment YoastPost on wordpress__POST {
        date(formatString: "MMMM DD, YYYY")
        modified(formatString: "MMMM DD, YYYY")
        yoast {
            title
            metadesc
            meta_robots_noindex
            canonical
            opengraph_title
            opengraph_description
            opengraph_image
            twitter_title
            twitter_description
            twitter_image
        }
    }

    fragment YoastPage on wordpress__PAGE {
        date(formatString: "MMMM DD, YYYY")
        modified(formatString: "MMMM DD, YYYY")
        yoast {
            title
            metadesc
            meta_robots_noindex
            canonical
            opengraph_title
            opengraph_description
            opengraph_image
            twitter_title
            twitter_description
            twitter_image
        }
    }

    query SEO {
        site {
            buildTime(formatString: "YYYY-MM-DD")
            siteMetadata {
                siteUrl
                defaultTitle: title
                defaultDescription: description
                defaultType: type
                defaultAuthor: author
                defaultHeadline: headline
                siteLanguage
                ogLanguage
                twitter
                facebook
            }
        }
    }
`
