import React from 'react'
import { ThemedComponent } from '../theme'


export class NavIconOpen extends ThemedComponent {
    render() {        
        const { textColor } = this.context.theme
        const { color } = this.props
        let derivedColor = color || textColor
        return (
            <svg
                width="22px"
                height="16px"
                viewBox="0 0 22 16"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="square"
                >
                    <g
                        transform="translate(-329.000000, -28.000000)"
                        fillRule="nonzero"
                        stroke={derivedColor}
                        strokeWidth="2"
                    >
                        <g transform="translate(330.000000, 29.000000)">
                            <path d="M1.7069679e-15,0.5 L20,0.5" />
                            <path d="M1.7069679e-15,13.5 L20,13.5" />
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export class NavIconClose extends ThemedComponent {
    render() {
        // const { textColor } = this.context.theme
        // Close icon only ever shows up as white (on black menu modal)
        const textColor = '#FFFFFF'
        return (
            <svg
                width="18px"
                height="17px"
                viewBox="0 0 18 17"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="square"
                >
                    <g
                        transform="translate(-331.000000, -21.000000)"
                        fillRule="nonzero"
                        stroke={textColor}
                        strokeWidth="2"
                    >
                        <g transform="translate(332.000000, 22.000000)">
                            <path
                                d="M-2,7.5 L18,7.5"
                                transform="translate(8.000000, 7.500000) rotate(45.000000) translate(-8.000000, -7.500000) "
                            />
                            <path
                                d="M-1.5753788,7.4246212 L18.4246212,7.4246212"
                                transform="translate(8.424621, 7.424621) rotate(-45.000000) translate(-8.424621, -7.424621) "
                            />
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}