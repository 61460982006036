import React, { Component } from 'react'
import { navigate } from 'gatsby'
import websiteConfig from '../../../config/website'
import classNames from 'classnames'

export default class Wysiwyg extends Component {
    constructor(props) {
        super(props)
        this.contentRef = React.createRef()
        this.boundClickListener = this._clickListener.bind(this)
    }
    componentDidMount() {
        this.contentRef.current.addEventListener(
            'click',
            this.boundClickListener
        )
    }
    componentWillUnmount() {
        this.contentRef.current.removeEventListener(
            'click',
            this.boundClickListener
        )
    }
    _clickListener(event) {
        if (
            (event.target.tagName === 'A' &&
                event.target.target !== '_blank') ||
            (event.target.parentElement &&
                event.target.parentElement.tagName === 'A' &&
                event.target.parentElement.target !== '_blank')
        ) {
            let href = event.target.href || event.target.parentElement.href
            if (href.indexOf(websiteConfig.url) === 0) {
                event.preventDefault()
                let absolutePath = href.substring(websiteConfig.url.length)
                if (absolutePath[absolutePath.length - 1] === '/') {
                    absolutePath = absolutePath.substring(
                        0,
                        absolutePath.length - 1
                    )
                }
                navigate(absolutePath)
            }
        }
    }
    render() {
        const { className, children, ...props } = this.props
        return (
            <div
                {...props}
                className={classNames('wysiwyg-content', className)}
                ref={this.contentRef}
                dangerouslySetInnerHTML={{ __html: children }}
            />
        )
    }
}
