import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './Hoverable.scss'
const HoverableContext = React.createContext('hoverable')

class HoverableOverlay extends Component {
    static displayName = 'Hoverable.Overlay'
    static contextType = HoverableContext
    static propTypes = {
        children: PropTypes.node,
        solidOverlay: PropTypes.bool,
    }
    render() {
        const { solidOverlay, children, ...props } = this.props
        return (
            <div
                style={{ position: 'relative', overflow: 'hidden' }}
                {...props}
            >
                <div
                    className={classNames('HoverableOverlayContent', {
                        active: this.context.isHovered,
                    })}
                >
                    {children}
                </div>
                <div
                    className={classNames('HoverableOverlay', {
                        active: this.context.isHovered,
                        solid: solidOverlay,
                    })}
                />
            </div>
        )
    }
}

class Hoverable extends Component {
    static displayName = 'Hoverable.Container'
    constructor() {
        super()
        this.state = {
            isHovered: false,
        }
        this.onMouseEvent = this.toggleHoverState.bind(this)
    }
    componentDidMount() {}
    toggleHoverState = () => {
        this.setState({ isHovered: !this.state.isHovered })
    }
    render() {
        const { component, ...props } = this.props
        const Comp = component || 'div'
        return (
            <Comp
                {...props}
                onMouseEnter={this.onMouseEvent}
                onMouseLeave={this.onMouseEvent}
            >
                <HoverableContext.Provider
                    value={{ isHovered: this.state.isHovered }}
                >
                    {this.props.children}
                </HoverableContext.Provider>
            </Comp>
        )
    }
}

Hoverable.propTypes = {
    children: PropTypes.node.isRequired,
    component: PropTypes.any,
}

export default {
    Container: Hoverable,
    Overlay: HoverableOverlay,
}
