import React, { Component } from 'react'
import IdeasListItem from '../ideas-list-item/IdeasListItem'
import './MoreIdeas.scss'

export default class MoreIdeas extends Component {
    render() {
        const { post_type, posts } = this.props
        if (!posts.length) {
            return null
        }
        return (
            <section className="more-ideas mb-yd">
                <h3 className="type-label">
                    More {post_type === 'post' ? 'Stories' : 'Tutorials'}
                </h3>
                <section className="ideas-container container-fluid">
                    <div className="row">
                        {posts
                            ? posts.map(node => {
                                  const post = node.node
                                  return (
                                      <section
                                          key={post.id}
                                          className="col-12 col-sm-4"
                                      >
                                          <IdeasListItem post={post} />
                                      </section>
                                  )
                              })
                            : null}
                    </div>
                </section>
            </section>
        )
    }
}
