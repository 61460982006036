import React, { Component } from 'react'
import PropTypes from 'prop-types'
const ThemeContext = React.createContext('theme')

export const ThemePropType = PropTypes.oneOf(['light', 'dark', 'lightGradient'])

const themes = {
    light: {
        name: 'light',
        backgroundClass: 'bg-white',
        textClass: 'text-black',
        textColor: '#000000',
    },
    dark: {
        name: 'dark',
        backgroundClass: 'bg-black',
        textClass: 'text-white',
        textColor: '#FFFFFF',
    },
    lightGradient: {
        name: 'lightGradient',
        backgroundClass: 'bg-transparent',
        textClass: 'text-black',
        textColor: '#000000',
    },
}

/**
 * This component assumes a single instance will be mounted
 * at a given time.
 */
export default class PageTheme extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        theme: ThemePropType.isRequired,
    }
    componentDidMount() {
        document.body.classList.add(`theme-${this.props.theme}`)
    }
    componentWillUnmount() {
        document.body.classList.remove(`theme-${this.props.theme}`)
    }
    render() {
        return (
            <ThemeContext.Provider value={{ theme: themes[this.props.theme] }}>
                <div className="theme-backdrop" />
                {this.props.children}
            </ThemeContext.Provider>
        )
    }
}

export class ThemedComponent extends Component {
    static contextType = ThemeContext
}
