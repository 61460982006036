import React, { Component } from 'react'
import {
    LinkedinShareButton,
    FacebookShareButton,
    TwitterShareButton,
    EmailShareButton,
    LinkedinIcon,
    FacebookIcon,
    TwitterIcon,
    EmailIcon,
} from 'react-share'
import './share-component.scss'
import { StaticQuery, graphql } from 'gatsby';

export default class ShareComponent extends Component {
    render() {
        //Might want to build a switch for below based on theme if we used this anywhere else.
        const iconProps = {
            size: 36,
            iconBgStyle: { fill: 'rgb(255,255,255)' },
            logoFillColor: 'black',
        }
        return (
            <StaticQuery
                query= {
                    graphql`
                    query {
                        site {
                            siteMetadata {
                                siteUrl
                            }
                        }
                    }
                    `
                }
                render={data => {
                    const siteUrl = new URL(data.site.siteMetadata.siteUrl)
                    const pageUrl = new URL(this.props.slug, siteUrl)
                    
                    const shareProps = {
                        url: pageUrl
                    }
                    return (
                        <section className="share-container container-fluid">
                            <div className="row">
                                <div className="col-sm-6 offset-sm-3">
                                    <hr className="d-none d-md-block" />
                                    <h4 className="share-title">Share</h4>
                                    <section className="share-buttons d-flex align-items-center justify-content-start">
                                        <LinkedinShareButton {...shareProps}>
                                            <LinkedinIcon {...iconProps} />
                                        </LinkedinShareButton>
                                        <FacebookShareButton {...shareProps}>
                                            <FacebookIcon {...iconProps} />
                                        </FacebookShareButton>
                                        <TwitterShareButton {...shareProps}>
                                            <TwitterIcon {...iconProps} />
                                        </TwitterShareButton>
                                        <EmailShareButton {...shareProps}>
                                            <EmailIcon {...iconProps} />
                                        </EmailShareButton>
                                    </section>
                                </div>
                            </div>
                        </section>
                    )
                }}
            />
        )
    }
}
