import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './Header'

const HeaderWrapped = ({ ...props }) => (
    <StaticQuery
        query={graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
                allWordpressWpApiMenusMenusItems(
                    filter: { slug: { eq: "english" } }
                ) {
                    edges {
                        node {
                            name
                            items {
                                title
                                url
                                type
                                object_slug
                            }
                        }
                    }
                }
            }
        `}
        render={data => (
            <Header
                {...props}
                siteTitle={data.site.siteMetadata.title}
                menuItems={
                    data.allWordpressWpApiMenusMenusItems.edges[0].node.items
                }
            />
        )}
    />
)

HeaderWrapped.propTypes = {
    fixed: PropTypes.bool.isRequired,
    headroomProps: PropTypes.object,
}
HeaderWrapped.defaultProps = {
    fixed: true,
    headroomProps: {},
}

export default HeaderWrapped
