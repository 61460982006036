export const mimeTypeFromFileExtension = ext => {
    switch (ext) {
        case '.mp4':
            return 'video/mp4'
        case '.webm':
            return 'video/webm'
        default:
            console.warn(
                `mimeTypeFromFileExtension helper has not accounted for extension of: ${ext}`
            )
            return 'video'
    }
}

export const absolutePathFromUrl = url => {
    let pathIndex = url.indexOf('.com')
    let absolutePath = url.substring(pathIndex + 4)
    if (absolutePath[absolutePath.length - 1] === '/') {
        absolutePath = absolutePath.substring(0, absolutePath.length - 1)
    }
    return absolutePath
}

export const stripPTagFromContent = content => {
    let strippedContent = content
    if (strippedContent.indexOf('<p>') === 0) {
        strippedContent = strippedContent.substr(3)
    }
    if (strippedContent.indexOf('</p>') > -1) {
        strippedContent = strippedContent.substr(
            0,
            strippedContent.indexOf('</p>')
        )
    }
    return strippedContent
}

export function getContrastColor(hexcolor) {
    var r = parseInt(hexcolor.substr(0, 2), 16)
    var g = parseInt(hexcolor.substr(2, 2), 16)
    var b = parseInt(hexcolor.substr(4, 2), 16)
    var yiq = (r * 299 + g * 587 + b * 114) / 1000
    return yiq >= 128 ? 'black' : 'white'
}
