import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../../layouts/default/layout'
import FlexContent, {
    FlexTextHeading,
} from '../../components/flexcontent/flexcontent'
import ShareComponent from '../../components/share-component/ShareComponent'
import SEO from '../../components/seo'
import MoreIdeas from '../../components/more-ideas/MoreIdeas'
import './ideas-stories-item.scss'

export default class StoriesItem extends Component {
    constructor(props) {
        super(props)
        this._scrollHandlerBound = this._scrollHandler.bind(this)
        this.state = {
            headerTheme: 'dark',
        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this._scrollHandlerBound)
    }
    componentWillUnmount() {
        window.addEventListener('scroll', this._scrollHandlerBound)
    }
    _scrollHandler() {
        const headerHeight = 115
        if (window.pageYOffset > window.innerHeight - headerHeight / 2) {
            if (this.state.headerTheme === 'dark') {
                this.setState({ headerTheme: 'light' })
            }
        } else {
            if (this.state.headerTheme === 'light') {
                this.setState({ headerTheme: 'dark' })
            }
        }
    }
    render() {
        const {
            data: { story, relatedStories },
        } = this.props
        const {
            title,
            content,
            featured_media,
            acf,
            dateFormatted,
            author,
        } = story
        return (
            <Layout
                theme={'light'}
                headerSpacing={false}
                headerProps={{ themeOveride: this.state.headerTheme }}
            >
                <SEO
                    yoast={story.yoast}
                    article={true}
                    title={title}
                    type="Person"
                    author={author.name}
                    description={story.content}
                    dateModified={story.modified}
                    datePublished={story.date}
                />
                <main className="StoriesItem">
                    <header className="mb-yd">
                        {featured_media && featured_media.localFile ? (
                            <Img
                                critical
                                fluid={
                                    featured_media.localFile.childImageSharp
                                        .fluid
                                }
                                alt={featured_media.alt_text}
                                title={featured_media.title}
                            />
                        ) : (
                            <div className="featured-image-placeholder" />
                        )}
                        <div className="content-overlay d-flex flex-column align-items-center justify-content-center h-100 w-100 px-yd">
                            <h1
                                dangerouslySetInnerHTML={{
                                    __html: title,
                                }}
                            />
                            <div className="type-link mb-1">{`Story by ${
                                author.name
                            }`}</div>
                            <div className="type-link">{dateFormatted}</div>
                        </div>
                    </header>
                    <div className="flex-wrapper px-yd mb-yd">
                        {content && <FlexTextHeading flexItem={{ content }} />}
                        {acf && <FlexContent contents={acf.contents_post} />}
                    </div>
                    <div className="px-yd">
                        <ShareComponent slug={`/ideas/stories/${story.slug}`} />
                    </div>
                    <div className="px-yd">
                        <MoreIdeas
                            posts={relatedStories ? relatedStories.edges : []}
                            post_type="post"
                        />
                    </div>
                </main>
            </Layout>
        )
    }
}

export const pageQuery = graphql`
    query($id: String!, $storyTagIds: [Int!]) {
        story: wordpressPost(id: { eq: $id }) {
            ...YoastPost
            slug
            id
            title
            content
            dateFormatted: date(formatString: "MM.DD.YYYY")
            author {
                name
            }
            acf {
                contents_post {
                    __typename
                    ... on WordPressAcf_image {
                        ...FlexContentImageFragment
                    }
                    ... on WordPressAcf_two_image {
                        ...FlexContentTwoImageFragment
                    }
                    ... on WordPressAcf_quote {
                        ...FlexContentQuoteFragment
                    }
                    ... on WordPressAcf_text_heading {
                        ...FlexContentTextHeadingFragment
                    }
                    ... on WordPressAcf_text_and_image {
                        ...FlexContentTextImageFragment
                    }
                    ... on WordPressAcf_two_text {
                        ...FlexContentTwoTextFragment
                    }
                    ... on WordPressAcf_code {
                        ...FlexContentCodeFragment
                    }
                    ... on WordPressAcf_single_video {
                        ...FlexContentVideoFragment
                    }
                    ... on WordPressAcf_two_video {
                        ...FlexContentTwoVideoFragment
                    }
                }
            }
            featured_media {
                alt_text
                title
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 1440) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        } #End of Story Query
        relatedStories: allWordpressPost(
            limit: 3
            filter: {
                tags: { elemMatch: { wordpress_id: { in: $storyTagIds } } }
                id: { ne: $id }
            }
        ) {
            edges {
                node {
                    ...IdeasStoryListItemFragment
                }
            }
        }
    }
`
